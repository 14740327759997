<template>
  <div class="collecVideoList">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <VideoList ref="videoList" class="videoL" :dataList="list" :isEdit="isEdit" @delItem="delItem" :page="pageNumber" />
    </PullRefresh>
  </div>
</template>

<script>
import { Toast } from 'vant';
import VideoList from '@/components/VideoList';
import PullRefresh from '@/components/PullRefresh';
import { queryCollecList } from '@/api/mine';
import { careCollectVideo } from '@/api/user';
import { getSessionItem } from '@/utils/longStorage';
export default {
  name: 'CollecVideoList',
  components: {
    VideoList,
    PullRefresh,
  },
  data() {
    return {
      isEdit: false,
      list: [],
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 修改编辑状态
    changeEditSta(isEdit) {
      this.isEdit = isEdit;
    },
    // 选择全部
    selectAll(isSelectAll) {
      this.$nextTick(() => {
        this.$refs.videoList.selectAll(isSelectAll);
      });
    },
    // 批量删除
    batchDel() {
      this.$refs.videoList.batchDel();
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 'video', // 影视-movie 图文-img 视频-video 话题-tag
        uid: JSON.parse(getSessionItem('userInfo')).uid,
      };
      try {
        let res = await this.$Api(queryCollecList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          // this.isNoData = this.comList.length > 0 ? false : true;
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    // 取消收藏
    async delItem(delArr) {
      let req = {
        type: 'video',
        objIds: delArr,
        isCollect: false,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(careCollectVideo, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          this.pageNumber = 1;
          this.loading = true;
          this.getList('refresh');
          Toast('删除成功');
          return;
        }
        Toast('操作失败');
      } catch (e) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collecVideoList {
  height: calc(100% - 92px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .videoL {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
}
</style>
